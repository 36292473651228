import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {
  getPageGridModel,
  IPageWithGridModel,
  PageWithGridComponent
} from '../../../oex-ui-kit/base/page-with-grid.component';
import { INewsletterLog} from '../../services/api.types';
import {APIService} from '../../services/api.service';
import {FilterComponent, IDictionaryResponse} from '../../../oex-ui-kit/components/filter/filter.component';
import {StorageService} from '../../services/storage.service';
import {GridComponent, IGridColumnDef, IGridConfig, IRowActionEvent} from '../../../oex-ui-kit/components/grid/grid';
import {NgSelectModule} from '@ng-select/ng-select';
import {DialogService} from '../../services/dialog.service';
import {ModalService} from '../../services/modal.service';
import {ScrollVisibilityDirective} from "../../../oex-ui-kit/directives/scroll-visibility.directive";

export interface INewsletterLogModel extends IPageWithGridModel<INewsletterLog> {
}

export const resolveNewsletterLog: ResolveFn<INewsletterLogModel> = async (route: ActivatedRouteSnapshot) => {
  const api = inject(APIService);
  const st = inject(StorageService);
  const model: INewsletterLogModel = {
    ...getPageGridModel<INewsletterLog>(route, [
      {
        id: 'newsletter',
        label: 'Newsletter',
        dropdownSearch: true,
        multiple: true
      },
      {
        id: 'date_from',
        label: 'Date from',
        type: 'date'
      },
      {
        id: 'date_to',
        label: 'Date to',
        type: 'date'
      },
      {
        id: 'startup',
        label: 'Startup',
        dropdownSearch: true,
        multiple: true
      },
    ])
  };
  /* const roleFilter = {
     id: 'roles', label: 'Roles', items: []
   } as IFilterDesc;
   const model: INewsletterLogModel = {
     roles: [],
     ...getPageGridModel<IAdminUser>(route, [
       roleFilter
     ])
   };*/
  FilterComponent.loadFilterValuesFromStorage(model.filters, st, 'csh-newsletter-log');

  try {
    model.data = await api.getNewsletterLog(model.search, model.sorting, model.filters, model.page);
  } catch (e) {
    console.error(e);
  }

  return model;
}

@Component({
  selector: 'st-newsletter-log-page',
  imports: [
    ScrollVisibilityDirective,
    FilterComponent,
    GridComponent,
    NgSelectModule
  ],
  templateUrl: './newsletter-log-page.component.html',
  styleUrl: './newsletter-log-page.component.scss'
})
export class NewsletterLogPageComponent extends PageWithGridComponent<INewsletterLogModel> implements OnInit {
  columnsDef: IGridColumnDef[] = [
    {
      text: 'Newsletter Name',
      field: 'name',
      sorting: 'n',
      initialSorting: 'asc',
      template: 'textColumn'
    },
    {
      text: 'Sent Date',
      field: 'datetime',
      sorting: 'd',
      template: 'date'
    },
    {
      text: 'Recipient Email',
      field: 'toAddress',
      sorting: 'to',
      template: 'textColumn'
    },
    {
      text: 'Startup',
      field: 'startup',
      sorting: 'sn',
      template: 'textColumn'
    },
    {
      text: 'Status',
      field: 'status',
      template: 'statusColumn'
    },
    {
      text: '',
      field: '',
      template: 'resentButton'
    },
  ];
  override config: IGridConfig = {
    sortingField: 'n',
    sortingOrder: 'asc'
  };
  protected override resolver = resolveNewsletterLog;
  private ds = inject(DialogService);
  private modal = inject(ModalService);
  private api = inject(APIService);

  override ngOnInit() {
    super.ngOnInit();
    this.data$.subscribe(d => {
      this.setFilterItems(d.dict);
    });
  }

  private setFilterItems(dict: IDictionaryResponse) {
    let flt = this.model.filters?.find(f => f.id === 'newsletter');
    if (flt) {
      flt.items = dict['newsletter'];
    }
    flt = this.model.filters?.find(f => f.id === 'startup');
    if (flt) {
      flt.items = dict['startup'];
    }
  }

  protected askForResend(row: INewsletterLog) {
    void this.ds.showConfirmDialog(`Are you sure you want to resend "${row.name}" newsletter?`, async () => {
      this.resend(row.id);
    });
  }

  private async resend(id: number) {
    this.ps.show();
    try {
      await this.api.resendNewsletter(id);
      void this.modal.show('Newsletter has been successfully resent.');
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }
}
