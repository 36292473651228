<!-- Title -->
<h3 class="ui-portal-title">
  Newsletter Log ({{ model.data.total || 0 }})

  <div class="ui-portal-title-buttons">
    <!-- Filter button -->
    <button
      class="btn-gray"
      [class.toggled]="isPopupFilters"
      ui-scroll-visibility="260"
      (click)="isPopupFilters = !isPopupFilters"
      (onHide)="hideFilters()"
    >
      <span class="ui-icon-filter mr10" [class.active]="isAnyFilter()"></span>Filters
    </button>
  </div>
</h3>

<ui-filter
  #filterComponent
  [items]="filters()"
  [term]="model.search"
  [multiline]="false"
  [isSearch]="false"
  [storeFilters]="true"
  storeKey="csh-newsletter-log"
  [showAsPopup]="isPopupFilters"
  (onSearch)="onSearch($event)"
  (onChange)="onFilter($event)"
></ui-filter>

<!-- Grid -->
<ui-grid
  #grid
  [data]="model.data"
  [config]="config"
  [fixedRows]="1"
  [fixedCols]="1"
  [fixedRowOffset]="50"
  [columns]="columnsDef"
  [noWrap]="true"
  [infiniteScroll]="true"
  (onSort)="onSorting($event)"
  (onNextPageLoad)="loadPage($event)"
>
  <!-- Template text column -->
  <ng-template #textColumn let-row="row" let-col="col">
    <span class="txt-limit" [title]="row[col.field]">{{row[col.field]}}</span>
  </ng-template>

  <!-- Template for status column -->
  <ng-template #statusColumn let-row="row" let-col="col">
    <span class="status-badge" [class.rejected]="row[col.field] === 'rejected'">{{row[col.field]}}</span>
  </ng-template>

  <!-- Roles column -->
  <ng-template #resentButton let-row="row" let-col="col">
    @if (row.resend) {
      <button
        class="btn-outline"
        (click)="askForResend(row)"
      >
        Resend
      </button>
    }
  </ng-template>
</ui-grid>
